/*eslint-disable */
import {bus} from '@/main';
export default {
  name: 'ngo-info',
  components: {},
  props: [],
  data () {
    return {

    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    signupPopupClosed(){
      bus.$emit('aboutUsPopupStateBus', false)
      bus.$emit('landingMainContentBus', true)
    },
    signupPopupClosedOnLogo(){
      bus.$emit('aboutUsPopupStateBus', false)
      bus.$emit('landingMainContentBus', true)
    },
  }
}